import { useDispatch } from "react-redux";
import { envResolverAPIURL, envResolverAuthAPIURL } from "../env";
import { store } from "./store";
import { showMessage } from "../components/muiCustom/messageTip";
import { logout, setLogin } from "./slice/AuthSlice";

type APIFunctionServer<T,P> = {
  body?: T,
  newUrl?: string,
  onSuccess?: (res: APIReturnServer<P>) => void,
  onError?: (err: any) => void,
  onFinally?: () => void
  notTip?: boolean
}

function createImmeditFetch<T,P>({
  url,
  method = 'GET',
  headers,
  http_path = "",
}:{
  url: string,
  method?: string,
  headers?: {[key: string]: string},
  http_path?: string
}){
  const dispatch = useDispatch();
  
  function post_update_tokens(arg: APIFunctionServer<T,P> ){
    const requestPath = envResolverAuthAPIURL();
    const refresh_token = store.getState().auth.refresh_token;
    fetch(requestPath + 'refresh_token', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ refresh_token }),
    }).then((res)=>{
      if(res.ok){ return res.json(); }else{ return Promise.resolve({status_code: 401}); }
    }).then((res) => {
      if(res.status_code === 200){
        dispatch( setLogin(res.data) );
        initiate_request(arg, true);
      }else{
        dispatch( logout() );
      }
    }).catch((err) => {
      dispatch( logout() );
    });
  }
  
  function initiate_request(
    { body, newUrl, onSuccess, onError, onFinally, notTip }:APIFunctionServer<T,P>,
    isRefresh?: boolean,
  ){
    const access_token = store.getState().auth.access_token;

    const controller = new AbortController();
    const newHeader = new Headers(headers);
    if(access_token){
      newHeader.set( "Authorization", "Bearer " + access_token )
    };

    let newBody = "" as FormData | string;
    let rejectUrl = newUrl || url;
    const typeData = Object.prototype.toString.call(body);
    if(typeData === '[object Object]'){
      if(method.toLowerCase() === "get" && body){
        newHeader.set('Content-Type', 'application/x-www-form-urlencoded');
        newBody = Object.keys(body).map((key) => key + '=' + (body as any)[key]).join('&');
        rejectUrl += "?" + newBody;
      }else{
        newHeader.set('Content-Type', 'application/json');
        newBody = JSON.stringify(body);
      }
    }else if(typeData === '[object FormData]'){
      newBody = body as FormData;
    };

    let requestPath = http_path || envResolverAPIURL();
    
    fetch(requestPath + rejectUrl, {
      method,
      headers: newHeader,
      ...body && method.toLowerCase() !== "get" ? {body: newBody} : {},
      credentials: 'include',
      signal: controller.signal,
      cache: 'no-cache',
    }).then((res) =>{
      if(!res.ok){
        return Promise.reject(res);
      }else{
        return res.json();
      }
    }).then((res: APIReturnServer<P>) => {
      if(res.status_code === 401 && isRefresh){
        dispatch( logout() );
      }else if(res.status_code === 401 && !isRefresh){
        post_update_tokens({body, newUrl, onSuccess, onError, onFinally, notTip});
      }else if(res.status_code !== 200){
        onError && onError(res);
        !notTip && showMessage.error(res.message);
      }else{
        onSuccess && onSuccess(res);
      }
    }).catch((err:Response) => {
      if(err.status === 401 && isRefresh){
        dispatch( logout() );
      }else if(err.status === 401 && !isRefresh){
        post_update_tokens({body, newUrl, onSuccess, onError, onFinally});
      }else{
        onError && onError(err);
      }
    }).finally(() => {
      onFinally && onFinally();
    });
    return {
      cancel: () => { controller.abort(); }
    }
  };
  return initiate_request;
};

type APIFileFunctionServer<T> = {
  body?: T,
  newUrl?: string,
  onSuccess?: (res: Blob | File) => void,
  onError?: (err: any) => void,
  onFinally?: () => void
  notTip?: boolean
}

function createFileFetch<T>({
  url,
  method = 'GET',
  headers,
  http_path = "",
}:{
  url: string,
  method?: string,
  headers?: {[key: string]: string},
  http_path?: string
}){
  function initiate_request(
    { body, newUrl, onSuccess, onError, onFinally }:APIFileFunctionServer<T>
  ){
    const access_token = store.getState().auth.access_token;

    const controller = new AbortController();
    const newHeader = new Headers(headers);
    if(access_token){
      newHeader.set( "Authorization", "Bearer " + access_token )
    };

    let newBody = "" as FormData | string;
    let rejectUrl = newUrl || url;
    const typeData = Object.prototype.toString.call(body);
    if(typeData === '[object Object]'){
      if(method.toLowerCase() === "get" && body){
        newHeader.set('Content-Type', 'application/x-www-form-urlencoded');
        newBody = Object.keys(body).map((key) => key + '=' + (body as any)[key]).join('&');
        rejectUrl += "?" + newBody;
      }else{
        newHeader.set('Content-Type', 'application/json');
        newBody = JSON.stringify(body);
      }
    }else if(typeData === '[object FormData]'){
      newBody = body as FormData;
    };

    let requestPath = http_path || envResolverAPIURL();
    
    fetch(requestPath + rejectUrl, {
      method,
      headers: newHeader,
      ...body && method.toLowerCase() !== "get" ? {body: newBody} : {},
      credentials: 'include',
      signal: controller.signal,
    }).then((res) =>{
      if(!res.ok){
        return Promise.reject(res);
      }else{
        return res.blob();
      }
    }).then((res: Blob | File) => {
      onSuccess && onSuccess(res);
    }).catch((err:Response) => {
      onError && onError(err);
    }).finally(() => {
      onFinally && onFinally();
    });
    return {
      cancel: () => { controller.abort(); }
    }
  };
  return initiate_request;

}

export const usePost= function(){
  return createImmeditFetch<{},{}>({ method:"POST", url: '' })
};

// ----- Account -----
let http_path = envResolverAuthAPIURL();
export const usePostEmailCaptchaSend = function(){
  return createImmeditFetch<EmailCaptchaSendAPIRequest, {email: string}>({ method:"POST", url: 'auth/email_captcha_send' })
};
export const usePostEmailCaptchaVerify = function(){
  return createImmeditFetch<EmailCaptchaVerifyAPIRequest, {cert: string,email:string}>({ method:"POST", url: 'auth/email_captcha_verify' })
};
export const usePostRegister = function(){
  return createImmeditFetch<RegisterAPIRequest, AccountAPIResponse>({ method:"POST", url: 'auth/register' })
};
export const usePostLogin = function(){
  return createImmeditFetch<LoginAPIRequest, AccountAPIResponse>({ method:"POST", url: 'auth/login' })
};
export const usePostLogout = function(){
  return createImmeditFetch<{refresh_token:string}, null | {}>({ method:"POST", url: 'auth/logout' })
};
export const usePostResetUserinfo = function(){
  return createImmeditFetch<{ username: string }, null>({ method:"POST", url: 'auth/reset_userinfo' })
};
export const useGetUserAPI = function(){
  return createImmeditFetch<undefined, UserInfoType>({ method:"GET", url: 'auth/user' })
};
export const usePostResetPassword = function(){
  return createImmeditFetch<{ new_password: string, cert: string }, null>({ method:"POST", url: 'auth/reset_password' })
};
export const usePostForgetPassword = function(){
  return createImmeditFetch<ForgotPasswordAPIRequest, null>({ method:"POST", url: 'auth/forget_password' })
};
// ----- Account -----

// Fragment upload
export const usePostFileUpload = ()=>{
  return createImmeditFetch<FormData, FileSplitUploadAPIResponse>({ method:"POST", url: 'file/upload' })
}
export type UploadDefaultCallBack = ReturnType< typeof usePostFileUpload >

export const usePostAvatarTrain = ()=>{
  return createImmeditFetch<UploadAPIRequest, {id: number}>({ method:"POST", url: 'avatar/train' })
}

export const usePostAvatarInfer = ()=>{
  return createImmeditFetch<AvatarInferAPIRequest, {}>({ method:"POST", url: 'avatar/infer' })
}
export const useGetAvatarInferTasks = ()=>{
  return createImmeditFetch<{ id?:string } | null, AvatarInferTasksAPIRequest[]>({ method:"GET", url: 'avatar/infer_tasks' })
}
export const useGetAvatarTrainAvatars = ()=>{
  return createImmeditFetch<null, TrainAvatarsAPIRequest[]>({ method:"GET", url: 'avatar/train_avatars' })
}
export const useDeleteAvatarTrainAvatars = ()=>{
  return createImmeditFetch<{ id: number }, {}>({ method:"DELETE", url: 'avatar/train_avatars' })
}

export const useGetMaterialsVoices = ()=>{
  return createImmeditFetch<null, MaterialsVoicesAPIResponse>({ method:"GET", url: 'materials/voices' })
}
export const usePostMaterialsVoiceSample = ()=>{
  return createFileFetch<VoiceSampleAPIRequest>({ method:"POST", url: 'materials/voice_sample' })
}

// tool
export const usePostGenerateInfo = ()=>{
  return createImmeditFetch<{product_link: string}, GenerateInfoAPIResponse>({ method:"POST", url: 'generate-info' })
}

export const usePostVideoDetail = ()=>{
  return createImmeditFetch<{video_id: string | number}, VideoDetailAPIResponse>({ method:"GET", url: 'video/detail' })
}
export const usePostProductMedia = ()=>{
  return createImmeditFetch<ProductMediaAPIRequest, ProductMediaAPIResponse>({ method:"POST", url: 'product-media' })
}
export const usePostProductIdeas = ()=>{
  return createImmeditFetch<ProductIdeasAPIRequest, ProductIdeasAPIResponse>({ method:"POST", url: 'product-ideas' })
}
export const usePostVideoScript = ()=>{
  return createImmeditFetch<VideoScriptAPIRequest, VideoScriptAPIResponse>({ method:"POST", url: 'video-script' })
}
export const useGetScript = ()=>{
  return createImmeditFetch<ScriptAPIRequest, APIScriptResponse>({ method:"GET", url: 'script' })
}

export const useGetVideoList = ()=>{
  return createImmeditFetch<VideoListAPIRequest, VideoDetailAPIResponse[]>({ method:"GET", url: 'video/list' })
}
export const useDeleteVideoList = ()=>{
  return createImmeditFetch<{video_id: string | number}, null>({ method:"DELETE", url: 'video/list' })
}
export const useGetVideoListbyid = ()=>{
  return createImmeditFetch<{ids: string}, VideoDetailAPIResponse[]>({ method:"GET", url: 'video/listbyid' })
}

export const useGetMediaList = ()=>{
  return createImmeditFetch<{video_id: string | number}, MediaUserMaterialInfo[]>({ method:"GET", url: 'media/list' })
}
export const useDeleteMediaList = ()=>{
  return createImmeditFetch<DeleteMediaListAPIRequest, {}>({ method:"DELETE", url: 'media/list' })
}

export const usePostMediaUpload = ()=>{
  return createImmeditFetch<FormData, FileSplitUploadAPIResponse>({ method:"POST", url: 'media/upload' })
}
export const usePostMediaResize = ()=>{
  return createImmeditFetch<MediaResizeAPIRequest, MediaResizeAPIResponse>({ method:"POST", url: 'media/resize' })
}
export const usePostVideoInfo = ()=>{
  return createImmeditFetch<VideoInfoUpdateRequest, null>({ method:"POST", url: 'video/info' })
}

// material
export const useGetMaterialVecteezy = ()=>{
  return createImmeditFetch<MaterialVecteezyAPIRequest, MaterialVecteezyAPIResponse>({ method:"GET", url: 'material/vecteezy' })
}
export const usePostMediaDescription = ()=>{
  return createImmeditFetch<MediaDescriptionAPIRequest, MediaDescriptionAPIResponse>({ method:"POST", url: 'media/description' })
}

export const useGetMVDownloadOriginal = ()=>{
  return createImmeditFetch<DownloadOriginalAPIRequest, DownloadOriginalAPIResponse>({ method:"GET", url: 'material/vecteezy/download' })
}
export const usePostMVPDownload = ()=>{
  return createImmeditFetch<MVPDownloadAPIRequest, MVPDownloadAPIResponse>({ method:"POST", url: 'material/vecteezy/preview/download' })
}

export const useGetVideoBgmList = ()=>{
  return createImmeditFetch<{}, VideoBgmListAPIResponse>({ method:"GET", url: 'video/bgm-list' })
}

export const usePostVideoScriptEdit = ()=>{
  return createImmeditFetch<ScriptEditAPIRequest, VideoScriptAPIResponse>({ method:"POST", url: 'video/script/edit' })
}

export const usePostVideoShare = ()=>{
  return createImmeditFetch<VideoShareAPIRequest, {share_code: string}>({ method:"POST", url: 'video/share' })
}

export const useGetVideoShare = ()=>{
  return createImmeditFetch<{share_code: string}, {info: string}>({ method:"GET", url: 'video/share' })
}

export const usePostMaterialSearch = ()=>{
  return createImmeditFetch<MaterialSearchAPIRequest, MaterialSearchInfo[]>({ method:"POST", url: 'material/search' })
}

export const usePostVideoSizeAdjust = ()=>{
  return createImmeditFetch<VideoSizeAdjustAPIRequest, VideoSizeAdjustAPIResponse>({ method:"POST", url: 'video/size/adjust' })
}

export const usePostFeedback = ()=>{
  return createImmeditFetch<FeedbackAPIRequest, {}>({ method:"POST", url: 'feedback' })
}

export const useGetProducts = ()=>{
  return createImmeditFetch<{page: number, page_size: number}, ProductsDataInfo[]>({ method:"GET", url: 'products' })
}

export const usePostProductReIdeas = ()=>{
  return createImmeditFetch<ProductReIdeasAPIRequest, ProductReIdea[]>({ method:"POST", url: 'product/re-ideas' })
}

export const usePostScriptRegen = ()=>{
  return createImmeditFetch<ScriptRegenAPIRequest, VideoScriptAPIResponse>({ method:"POST", url: 'video/script/regen' })
}

// manually add

export const usePostBrandDetail = ()=>{
  return createImmeditFetch<DrandDetailAPIResponse, {id: string}>({ method:"POST", url: 'material/brand/detail' })
}

export const useGetBrandList = ()=>{
  return createImmeditFetch<null, BrandItemType[]>({ method:"GET", url: 'material/brand/list' })
}

export const usePostProductDetail = ()=>{
  return createImmeditFetch<ProductDetailAPIResponse, {id: string}>({ method:"POST", url: 'material/product/detail' })
}

export const useGetProductList = ()=>{
  return createImmeditFetch<null, ProductItemType[]>({ method:"GET", url: 'material/product/list' })
}

export const usePostMaterialSubmit = ()=>{
  return createImmeditFetch<MaterialSubmitRequest, {count: number}>({ method:"POST", url: 'material/submit' })
}

export const usePostCreateByProduct = ()=>{
  return createImmeditFetch<CreateByProductAPIRequest, CreateByProductAPIResponse>({ method:"POST", url: 'video/create-by-product' })
}

export const useDeleteProducts = ()=>{
  return createImmeditFetch<{product_id: string}, {}>({ method:"DELETE", url: 'products' })
}

export const usePutProductDetail = ()=>{
  return createImmeditFetch<EditProductDetailAPIRequest, null>({ method:"PUT", url: 'product/detail' })
}

export const usePostSlideShowCreate = ()=>{
  return createImmeditFetch<{product_link: string}, SlideShowCreateResponse>({ method:"POST", url: 'slide-show/create' })
}

export const useGetSlideShowDetail = (slide_show_id: string)=>{
  return createImmeditFetch<null, SlideShowDetailResponse>({ method:"GET", url: 'slide-show/detail/'+slide_show_id })
}

export const useGetSlideShowMedias = ()=>{
  return createImmeditFetch<SlideShowMediasRequest, SlideShowMediaItem[]>({ method:"GET", url: 'slide-show/media/list' })
}

export const usePostSlideShowUpload = ()=>{
  return createImmeditFetch<FormData, FileSplitUploadAPIResponse>({ method:"POST", url: 'slide-show/file/upload' })
}

export const usePutSlideShowDetail = (slide_show_id: string)=>{
  return createImmeditFetch<SlideShowDetailPutRequest, null>({ method:"PUT", url: 'slide-show/detail/'+slide_show_id })
}

export const usePostSlideShowDownload = ()=>{
  return createImmeditFetch<SlideShowDownloadRequest, {url: string}>({ method:"POST", url: 'slide-show/media/download' })
}

export const useGetSlideShowList = ()=>{
  return createImmeditFetch<VideoListAPIRequest, SlideShowPageItem[]>({ method:"GET", url: 'slide-show/list' })
}

export const usePostSlideShowGenerate = ()=>{
  return createImmeditFetch<SlideShowGenerateRequest, SlideShowDetailResponse>({ method:"POST", url: 'slide-show/generate-imgs' })
}

export const usePostSlideShowOperation = ()=>{
  return createImmeditFetch<SlideShowOperation, {slide_show_id: string | number}>({ method:"POST", url: 'slide-show/operation' })
}

export const useGetSlideShowOperation = ()=>{
  return createImmeditFetch<{slide_show_id: string}, SlideShowOperation>({ method:"GET", url: 'slide-show/operation' })
}

export const useDeleteSlideShowImage = ()=>{
  return createImmeditFetch<SlideShowImageDeleteRequest, null>({ method:"DELETE", url: 'slide-show/image/delete' })
}

export const usePostSlideImageUpload = ()=>{
  return createImmeditFetch<FormData, SlideImageUploadResponse>({ method:"POST", url: 'slide-show/image/upload' })
}

export const usePostSlideshowGenerate = ()=>{
  return createImmeditFetch<FormData, SlideshowGenerateResponse>({ method:"POST", url: 'slide-show/generate-video' })
}

export const useDeleteSlideshowList = ()=>{
  return createImmeditFetch<{slide_show_id: string | number}, null>({ method:"DELETE", url: 'slide-show/list' })
}

export const usePostSlideImageResize = ()=>{
  return createImmeditFetch<SlideImageResizeRequest, {resized_img_url: string}>({ method:"POST", url: 'slide-show/image/resize' })
}

export const usePostSlideImageReplace = ()=>{
  return createImmeditFetch<FormData, SlideShowDetailResponse>({ method:"POST", url: 'slide-show/image/replace' })
}

export const usePostSlideProducts = ()=>{
  return createImmeditFetch<VideoListAPIRequest, SlideshowProductItem[]>({ method:"GET", url: 'slide-show/products' })
}

export const usePostSlideCreatByProduct = ()=>{
  return createImmeditFetch<{product_id: number | string}, SlideShowCreateResponse>({
    method:"POST", url: 'slide-show/create-by-product'
  })
}

export const usePostMaterialUpload = ()=>{
  return createImmeditFetch<FormData, SlideImageUploadResponse>({method:"POST", url: 'material/upload'})
}

export const usePostMUploadedSubmit = ()=>{
  return createImmeditFetch<MUploadedSubmitRequest, null>({method:"POST", url: 'material/uploaded/submit'})
}

export const usePutTrainAvatarDetail = ()=>{
  return createImmeditFetch<TrainAvatarDetailRequest, null>({method:"PUT", url: 'avatar/train_avatar/detail'})
}

export const useGetSlideMaterials = ()=>{
  return createImmeditFetch<GetSlideMaterialsRequest, SlideMaterialsItem[]>({method:"GET", url: 'slide-show/material/list'})
}

export const useUploadSlideMaterial = ()=>{
  return createImmeditFetch<FormData, {}>({method:"POST", url: 'slide-show/material/upload'})
}

export const usePostStripeSession = ()=>{
  return createImmeditFetch<{subscription_key: string}, {redirect_url: string}>({
    method:"POST", url: 'stripe/create_checkout_session'
  })
}

export const usePostPlatformException = ()=>{
  return createImmeditFetch<{message: string}, null>({method:"POST", url: 'platform/exception'})
}
// promote products
export const usePostPromoteProducts = ()=>{
  return createImmeditFetch<{products: PromoteProductParam[]}, PromoteProductsParam[]>({method:"POST", url: 'promote/get-products-info'})
}
export const usePostPromoteSelection = ()=>{
  return createImmeditFetch<PromoteSelectionParam, PromoteSelectionResponse[]>({method:"POST", url: 'promote/product-selection'})
}

export const usePostPromoteGenScript = ()=>{
  return createImmeditFetch<PromoteGenScriptRequest, PromoteGenScriptResponse>({method:"POST", url: 'promote/gen-script'})
}

export const usePostPromoteCreateVideo = ()=>{
  return createImmeditFetch<FormData, {video_id: string}>({method:"POST", url: 'promote/create-promotional-video'})
}

export const useGetPromotePromotional = (video_id: string)=>{
  return createImmeditFetch<null, PromotePromotionalResponse>({method:"GET", url: 'promote/promotional-video/' + video_id})
}

export const useGetPromotionalVideos = ()=>{
  return createImmeditFetch<{page: number, page_size: number}, PromotePromotionalResponse[]>({method:"GET", url: 'promote/promotional-videos'})
}

export const usePostRecordOp = (video_id: string)=>{
  let url = "record_op";
  if(video_id.length === 16) url = "promote/record-op";
  return createImmeditFetch<RecordOpAPIRequest, {video_id: string}>({ method:"POST", url })
}
export const useGetRecordOp = (video_id: string)=>{
  let url = "record_op";
  if(video_id.length === 16) url = "promote/record-op";
  return createImmeditFetch<{video_id: string}, RecordOpAPIRequest>({ method:"GET", url })
}
export const usePostVideoComposite = (video_id: string)=>{
  let url = "video/composite";
  if(video_id.length === 16) url = "promote/compositing-video";
  return createImmeditFetch<FormData, {}>({ method:"POST", url })
}

export const usePostMSlidesPic = ()=>{
  return createImmeditFetch<FormData, {id: string}>({method:"POST", url: 'material/slides-pic'})
}

export const useGetMSlidesList = ()=>{
  return createImmeditFetch<{page: number, page_size: number}, MaterialSliderParam[]>({method:"GET", url: 'material/slides-list'})
}

export const useGetMSlidesDownload = ()=>{
  return createImmeditFetch<{slides_id: string, with_video: boolean }, {compressed_slides: string}>({method:"GET", url: 'material/slides-download'})
}

export const usePostMSlidesFoods = ()=>{
  return createImmeditFetch<FormData, {id: string}>({method:"POST", url: 'material/slides-foods'})
}
export const useGetSlidesDetail = (video_id: string)=>{
  return createImmeditFetch<FormData, MaterialSliderParam>({ method:"GET", url: "material/slides-detail/" + video_id })
}


export const useDelPromotePromotional = (video_id: string)=>{
  return createImmeditFetch<null, null>({method:"DELETE", url: 'promote/promotional-video/' + video_id})
}

export const usePostPromoteSubtitle = function(){
  return createImmeditFetch<FormData, {}>({ method:"POST", url: 'promote/subtitle-data' })
};
